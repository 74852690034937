<template>
  <div class="projects wrapper">
    <div
      class="bigtitle wow animate__animated animate__fadeInUp"
      data-wow-duration="1s"
    >
      My Projects
    </div>
    <ul
      class="wow animate__animated animate__fadeInUp"
      data-wow-duration="1s"
    >
      <li
        v-for="(item, index) in namelist"
        :key="index"
        :class="{ active: index === activeIndex }"
        @click="activeIndex = index"
      >
        <a href="#">{{ item.name }}</a>
      </li>
    </ul>
    <div class="projectsShow" ref="projectsShow">
      <!-- <transition-group name="flip" tag="div"> -->

      <div
        v-for="(item, index) in filteredProjects"
        :key="item.id"
        :class="[`project-${index}`]"
        ref="projectItems"
      >
        <!-- <transition
          leave-active-class="animate__animated animate__fadeOutDown"
          enter-active-class="animate__animated animate__fadeInUp"
        > -->
          <div
            class="styleType1"
            v-if="
              item.styleType === 1 &&
              (item.tag - 1 === activeIndex || activeIndex === 0)
            "
          >
            <img :src="item.pic" />
            <div class="right">
              <div class="projName">{{ item.title }}</div>
              <p>{{ item.intro }}</p>
              <div class="button">
                <!-- <router-link :to="item.link">Learn More</router-link> -->
                <a :href="item.link" target="_blank">Learn More</a>
              </div>
            </div>
          </div>
        <!-- </transition>
        <transition
          leave-active-class="animate__animated animate__fadeOutDown"
          enter-active-class="animate__animated animate__fadeInUp"
        > -->
          <div
            class="styleType2 clearfix"
            v-if="
              item.styleType === 2 &&
              (item.tag - 1 === activeIndex || activeIndex === 0)
            "
          >
            <img :src="item.pic" />
            <div class="projName">{{ item.title }}</div>
            <p>{{ item.intro }}</p>
            <div class="button">
              <!-- <router-link :to="item.link">Learn More</router-link> -->
              <a :href="item.link" target="_blank">Learn More</a>
            </div>
          </div>
        <!-- </transition> -->
      </div>
      <!-- </transition-group> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeIndex: 0,
      namelist: [
        { tag: 1, name: "All" },
        { tag: 2, name: "Research" },
        { tag: 3, name: "Design" },
        { tag: 4, name: "SoftWare" },
        { tag: 5, name: "Others" },
      ],
      projectList: [
        {
          styleType: 1,
          tag: 4,
          title: "BVE5 Motor Assistance",
          intro:
            "Motor sound production software for BVE5 developers.",
          pic: "https://img.js.design/assets/img/6713a3ca48e0a8436430b65a.png#d41bae74e88c513e46dfd9cfb67adf09",
          link: "/Projects/Bve5MotorAssistance",
          id: 1,
        },
        {
          styleType: 2,
          tag: 3,
          title: "Golden Twilight",
          intro:
            "As the day wanes and the world slows its breath, a solitary scene unfolds upon the vast canvas of the sea.",
          pic: require("../../assets/info/gallery/huli-awsl-untitled1.jpg"),
          link: "https://www.artstation.com/artwork/xD50Km",
          id: 2,
        },
        {
          styleType: 2,
          tag: 3,
          title: "Cryostella",
          intro:
            "As the day wanes and the world slows its breath, a solitary scene unfolds upon the vast canvas of the sea.",
          pic: require("../../assets/projects/Cryostella.png"),
          link: "https://www.artstation.com/artwork/Evx0XA",
          id: 3,
        },
        {
          styleType: 1,
          tag: 5,
          title: "Simple-toon",
          intro:
            "A simple toon shader for Substance Painter.",
          pic: "https://img.js.design/assets/img/675d38a1d05e64e3170467ce.png#ae06345f1035f90dacb0ef81a4b67966",
          link: "https://github.com/huliawsl/simple-toon",
          id: 4,
        },
      ],
      prevPositions: new Map(),
    };
  },
  computed: {
    filteredProjects() {
      const activeTag = this.namelist[this.activeIndex].tag;
      return this.projectList.filter(
        (item) => activeTag === 1 || item.tag === activeTag
      );
    },
  },
  methods: {
    capturePositions() {
      this.$refs.projectItems.forEach((el) => {
        const rect = el.getBoundingClientRect();
        this.prevPositions.set(el, { top: rect.top, left: rect.left });
      });
    },

    applyFLIPAnimation() {
      this.$refs.projectItems.forEach((el) => {
        const prevPos = this.prevPositions.get(el);
        if (prevPos) {
          const newPos = el.getBoundingClientRect();
          const deltaX = prevPos.left - newPos.left;
          const deltaY = prevPos.top - newPos.top;

          if (deltaX !== 0 || deltaY !== 0) {
            el.style.transform = `translate(${deltaX}px, ${deltaY}px)`;
            el.style.transition = "none";

            requestAnimationFrame(() => {
              el.style.transition = "transform 0.8s, opacity 0.8s";
              el.style.transform = "translate(0, 0)";
              el.style.opacity = "1";
            });
          }
        } else {
          el.style.opacity = "0";
          requestAnimationFrame(() => {
            el.style.transition = "opacity 0.8s";
            el.style.opacity = "1";
          });
        }
      });
    },
    removeProject(index) {
      this.filteredProjects[index].isLeaving = true;
      setTimeout(() => {
        this.projectList.splice(index, 1);
      }, 500);
    },
  },

  watch: {
    filteredProjects: {
      handler() {
        this.$nextTick(() => {
          this.applyFLIPAnimation();
          this.capturePositions();
        });
      },
      deep: true,
    },
  },

  mounted() {
    this.capturePositions();
  },
};
</script>
<style lang="less" scoped>
.bigtitle {
  margin-top: 96px;
  height: 80px;
  font-family: "AlibabaSans";
  font-size: 60px;
  font-weight: 400;
  line-height: 80px;
  color: white;
  text-align: center;
  vertical-align: top;
}
.projects {
  ul {
    height: 44px;
    margin-top: 69px;
    li {
      float: left;
      margin-right: 50px;
      width: 200px;
      height: 44px;
      border-radius: 22px;
      border: 2px solid rgba(231, 196, 138, 1);
      text-align: center;
      cursor: pointer;
      a {
        margin-top: 4px;
        text-decoration: none;
        display: block;
        font-family: "Anonymous-Pro";
        font-size: 24px;
        font-weight: 400;
        color: white;
      }
    }
    li:nth-child(5) {
      margin-right: 0;
    }
    li.active {
      background-color: rgba(231, 196, 138, 1);
      a {
        color: black;
      }
    }
  }
}
.projectsShow {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  margin-top: 84px;
  margin-left: 20px;
  img {
    float: left;
    width: 504px;
    height: 275px;
    border-radius: 10px;
  }
  .button {
    margin-top: 36px;
    width: 150px;
    height: 44px;
    border-radius: 5px;
    border: 1px solid rgba(231, 196, 138, 1);
    a {
      margin-top: 6px;
      height: 44px;
      display: block;
      font-size: 20px;
      font-weight: 400;
      line-height: 28.96px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
    }
  }
  .button:hover {
    background-color: rgba(231, 196, 138, 1);
    a {
      color: black;
    }
  }
  .styleType1 {
    margin-top: 60px;
    height: 304px;
    .right {
      float: right;
      margin-left: 59px;
      width: 592px;
      font-family: "AlibabaSans";
      .projName {
        padding-bottom: 12px;
        border-bottom: 3px solid rgba(231, 196, 138, 1);
        font-size: 36px;
        font-weight: 400;
        line-height: 55.84px;
        color: rgba(255, 255, 255, 1);
      }
      p {
        margin-top: 19px;
        font-size: 24px;
        font-weight: 400;
        line-height: 37.22px;
        color: white;
      }
    }
  }
  .styleType2 {
    margin-top: 60px;
    margin-right: 50px;
    width: 504px;
    .projName {
      padding-bottom: 3px;
      font-size: 36px;
      font-weight: 400;
      line-height: 55.84px;
      color: rgba(255, 255, 255, 1);
    }
    p {
      margin-top: 19px;
      font-size: 24px;
      font-weight: 400;
      line-height: 37.22px;
      color: white;
    }
  }
  .isSecond {
    margin-right: 0;
  }
}


</style>
