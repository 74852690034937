var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('h2',{staticClass:"GalleryTitle wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"1s"}},[_vm._v(" Gallery ")]),_c('div',{staticStyle:{"height":"40px"}}),_c('div',{staticClass:"Glalery"},_vm._l((_vm.list),function(item,index){return _c('img',{key:index,class:[
        _vm.isSpecialIndex(index) ? 'GalImg' : 'GalImg2',
        { mL: (index + 1) % 2 === 0 },
        index % 2 === 0
          ? 'wow animate__animated animate__fadeInLeft'
          : 'wow animate__animated animate__fadeInRight',
      ],attrs:{"src":item.src}})}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }