<template>
  <div class="footer">
    <div class="wrapper clearfix">
      <div class="footLeft">
        <img :src="require('../assets/huli.svg')" alt="huli" />
        <div>©Huliawsl 2024</div>
      </div>
      <dl>
        <dt>Acknowledgement</dt>
        <dd>
          <a href="https://space.bilibili.com/299580185" target="_blank">UNF</a>
        </dd>
        <dd><a href="https://guanmu233.cn/" target="_blank">Guanmu</a></dd>
      </dl>
      <dl>
        <dt>Contact</dt>
        <dd><a href="#">huliawsl@foxmail.com</a></dd>
      </dl>
    </div>
  </div>
</template>

<script>

export default {};
</script>

<style lang="less">
.footer {
  margin-top: 80px;
  height: 246px;
  background-color: #171717;
  border-top: 5px solid #1c1c1c;
  .footLeft {
    margin-top: 55px;
    float: left;
    div {
      margin-top: 4px;
      height: 30px;
      font-family: deyi;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 3.5px;
      color: #fff;
    }
  }

  dl {
    float: right;
    margin-top: 62px;
    margin-left: 60px;
  }
  dt {
    font-family: Anonymous-Pro;
    font-size: 24px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 30px;
  }
  dd {
    margin-bottom: 12px;
    a {
      height: 30px;
      font-family: AlibabaSans;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #fff;
    }
  }
}
</style>