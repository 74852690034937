var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"projects wrapper"},[_c('div',{staticClass:"bigtitle wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"1s"}},[_vm._v(" My Projects ")]),_c('ul',{staticClass:"wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"1s"}},_vm._l((_vm.namelist),function(item,index){return _c('li',{key:index,class:{ active: index === _vm.activeIndex },on:{"click":function($event){_vm.activeIndex = index}}},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(item.name))])])}),0),_c('div',{ref:"projectsShow",staticClass:"projectsShow"},_vm._l((_vm.filteredProjects),function(item,index){return _c('div',{key:item.id,ref:"projectItems",refInFor:true,class:[`project-${index}`]},[(
            item.styleType === 1 &&
            (item.tag - 1 === _vm.activeIndex || _vm.activeIndex === 0)
          )?_c('div',{staticClass:"styleType1"},[_c('img',{attrs:{"src":item.pic}}),_c('div',{staticClass:"right"},[_c('div',{staticClass:"projName"},[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.intro))]),_c('div',{staticClass:"button"},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v("Learn More")])])])]):_vm._e(),(
            item.styleType === 2 &&
            (item.tag - 1 === _vm.activeIndex || _vm.activeIndex === 0)
          )?_c('div',{staticClass:"styleType2 clearfix"},[_c('img',{attrs:{"src":item.pic}}),_c('div',{staticClass:"projName"},[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.intro))]),_c('div',{staticClass:"button"},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v("Learn More")])])]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }