<template>
  <div class="wrapper">
    <h2
      class="InfoTitle wow animate__animated animate__fadeInUp"
      data-wow-duration="1s"
    >
      Info
  </h2>
    <div class="Intro">
      <div
        class="IntroLeft wow animate__animated animate__fadeInUp"
        data-wow-duration="1s"
      >
        <img src="../../assets/info/huli4big.png" />
      </div>
      <div class="IntroRight">
        <p
          v-html="intro"
          class="wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        ></p>
        <ul>
          <li
            class="wow animate__animated animate__fadeInUp"
            data-wow-duration="1s"
          >
            <div>{{ skiList[0].name }}</div>
            <p v-html="skiList[0].text"></p>
          </li>
          <li
            class="wow animate__animated animate__fadeInUp"
            data-wow-duration="1s"
          >
            <div>{{ skiList[1].name }}</div>
            <p v-html="skiList[1].text"></p>
          </li>
          <li
            class="wow animate__animated animate__fadeInUp"
            data-wow-duration="1s"
          >
            <div>{{ skiList[2].name }}</div>
            <p v-html="skiList[2].text"></p>
          </li>
          <li
            class="wow animate__animated animate__fadeInUp"
            data-wow-duration="1s"
          >
            <div>{{ skiList[3].name }}</div>
            <p v-html="skiList[3].text"></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      intro:
        "Hi,I am<br><br>Huli,a personal computer graphics<br>enthusiast and a 3D design<br>enthusiast, living in Beijing<br>China.",

      skiList: [
        {
          id: 1,
          name: "3D Design",
          text: "Avatars design, realistic <br>CG scenes and stylized <br>rendering.",
        },
        {
          id: 2,
          name: "Computer Graphics",
          text: "Interested in rendering and <br>3D reconstruction, <br>especially 3DGS.",
        },
        {
          id: 3,
          name: "App Development",
          text: "Web front-end development, form application development.",
        },
        {
          id: 4,
          name: "Others",
          text: "Chipi chipi chapa chapa Dubi dubi daba daba.",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.InfoTitle {
  font-family: sans-serifh;
  margin-top: 66px;
  width: 264px;
  font-size: 48px;
  font-weight: 400;
  color: white;
  border-bottom: 6px solid #e8ce81;
}
.Intro {
  margin-top: 44px;
  height: 919px;
  .IntroLeft {
    float: left;
    img {
      width: 326px;
      height: 919px;
    }
  }
  .IntroRight {
    float: right;

    p {
      font-family: Anonymous-Pro;
      color: white;
      font-size: 50px;
      font-weight: 400;
      letter-spacing: -2px;
    }

    ul {
      width: 830px;
      li {
        float: left;
        margin-top: 74px;
        margin-right: 46px;
        width: 376px;
        height: 221px;
        border-top: 1px solid #e8ce81;
      }
      li:nth-child(2n) {
        margin-right: 0px;
      }
      div {
        margin-top: 8.45px;
        font-family: Anonymous-Pro;
        font-size: 36px;
        font-weight: 400;
        line-height: 36px;
        color: white;
      }
      p {
        margin-top: 65px;
        font-family: AlibabaSans;
        font-size: 28px;
        font-weight: 200;
        line-height: 43.43px;
        letter-spacing: 0px;
        color: white;
      }
    }
  }
}
</style>
