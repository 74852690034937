import Vue from "vue";
import VueRouter from "vue-router";
import HuliProjectsPage from "@/view/HuliProjectsPage.vue";
import HuliInfo from "@/view/HuliInfo.vue";
import HuliContactPage from "@/view/HuliContactPage.vue";

const Bve5MotorAssistance = () =>
  import(
    "@/component/Projects/Projects/software/bve5motorassistance/Bve5MotorAssistance.vue"
  );
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HuliInfo",
    component: HuliInfo,
  },
  {
    path: "/Info",
    name: "HuliInfo",
    component: HuliInfo,
  },
  {
    path: "/Projects",
    name: "HuliProjects",
    component: HuliProjectsPage,
  },
  {
    path: "/Contact",
    name: "HuliContact",
    component: HuliContactPage,
  },
  {
    path: "/Projects/Bve5MotorAssistance",
    name: "Bve5MotorAssistance",
    component: Bve5MotorAssistance,
    meta: {
      title: "Bve5 Motor Assistance",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
